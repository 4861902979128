<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="ma-auto">
        <v-card outlined :loading="loading">
          <div class="px-8 pt-1 pb-12 text-center">
            <v-card-title class="text-center justify-center mb-3">
              <v-img
                  :src="require('@/assets/logo.png')"
                  alt="EndWait"
                  max-height="80"
                  max-width="120"
              />
            </v-card-title>
            <v-card-title class="text-center d-block font-weight-regular" style="line-height: 0.2rem;font-size: 24px">
              Forgot Password
            </v-card-title>
            <v-card-title class="text-center d-block mb-5 font-weight-regular"
                          style="line-height: inherit;font-size: 14px">
              Enter your Registered Email address
            </v-card-title>
            <v-card-text class="mt-8">
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <form
                    @submit.prevent="handleSubmit(SendOTPMail)"
                    @reset.prevent="reset"
                >
                  <ValidationProvider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                  >
                    <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        label="Email"
                        outlined
                    ></v-text-field>
                  </ValidationProvider>

                  <p class="text-center">
                    <v-btn
                        type="submit"
                        class="deep-orange white--text"
                        :loading="loading"
                        depressed
                    >Reset Password
                    </v-btn>
                  </p>

                  <div class="mt-2 d-flex justify-space-between">
                    <router-link
                        text
                        small
                        class="text-capitalize subtitle-1 deep-orange--text router-link-active"
                        to="signin">Sign in instead
                    </router-link>

                    <router-link
                        text
                        small
                        class="pl-0 text-capitalize deep-orange--text router-link-active subtitle-1"
                        router
                        to="signup"
                    >Create account
                    </router-link>
                  </div>
                </form>
              </ValidationObserver>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar"
    >
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {Auth} from 'aws-amplify';
import {mapGetters} from 'vuex';

export default {
  name: 'SendOTPMail',
  data: () => ({
    email: '',
    loading: false,
    snackbar: false,
    error: '',
  }),
  computed: {
    ...mapGetters(['getUrl'])
  },
  methods: {
    async SendOTPMail() {
      this.loading = true
      try {
        await Auth.forgotPassword(this.email)
            .then(async () => await this.$router.push({name: 'otpreset', params: {id: this.email}}))
            .catch(err => {
              this.error = "This email id not exist"
              this.snackbar = true
              console.log(err.message);
            });

      } catch (error) {
        this.error = error.message
        this.snackbar = true
      }
      this.loading = false
    }

  }
}
</script>

<style>
.v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 35px;
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 20px;
}

label.v-label.theme--light {
  font-size: 14px;
}
</style>
